<template>
	<div>
		<h2>已上传的{{ this.filetype }}</h2>
		<el-table :data="tableData" style="width: 100%" :row-class-name="tableRowClassName">
			<el-table-column prop="time" label="上传日期" width="280" />
			<el-table-column label="文件名（点击文件名可以预览pdf）">
				<template slot-scope="scope">
					<el-link :href="scope.row.viewpath" target="_blank">
						{{scope.row.name}}
					</el-link>
				</template>
			</el-table-column>
			<el-table-column prop="id" label="文件ID" width="80" v-if="isClub"/>
			<el-table-column prop="username" label="上传者" width="150"/>
			<el-table-column prop="status" label="状态" width="80" v-if="!isClub"/>
			<el-table-column prop="comment" label="评语" width="200" v-if="!isClub"/>
			<el-table-column fixed="right" label="操作" width="150">
				<template slot-scope="scope">
					<el-link :href="'/api/file/download?id=' + String(scope.row.id)">
						<i class="el-icon-download" />下载
					</el-link>
					<el-tooltip class="item" effect="dark" content="删除文件" placement="right">
						<el-button type="danger" @click="deleteFile(scope.row.id)" 
								size="small" circle style="margin: 15px" 
								v-if="scope.row.status === '未审核' && scope.row.username === username" plain
							><i class="el-icon-delete"></i></el-button>
					</el-tooltip>
				</template>
			</el-table-column>
		</el-table>
		<h2>请上传{{ this.filetype }}</h2>

		<el-upload
			style="display: inline-block"
			:limit="1"
			class="upload-demo"
			ref="upload"
			:action="uploadUrl"
			:file-list="fileList"
			:http-request="uploadSectionFile"
			:auto-upload="false"
			:before-upload="beforeUpload"
			:before-remove="handleRemove"
			accept=".zip, .pdf"
		>
			<el-button slot="trigger" size="small" type="primary" plain
				>选取文件</el-button
			>
			<el-button
				style="margin-left: 10px"
				size="small"
				icon="el-icon-upload2"
				type="success"
				@click="submitUpload"
				>上传</el-button
			>
			<div slot="tip" class="el-upload__tip">{{this.tipString}}</div>
		</el-upload>
	</div>
</template>

<style>
  .el-table .warning-row {
    background: oldlace;
  }

  .el-table .success-row {
    background: #f0f9eb;
  }
</style>

<script>
import axios from "axios";
import qs from "qs";
export default {
	data() {
		return {
			uploadUrl: "", // there'll be errors in console without this param
			fileList: [],
			headers: {},
			userid: "",
			isUploadNeeded: false,
			tableData: [{}],
			isClub: 0,
			tipString: "",
			username: "",
		};
	},
	props: {
		filetype: String,
	},
	beforeMount() {
		// console.log("filetype=");
		// console.log(this.filetype);
		if (localStorage.getItem("token")) {
			axios.defaults.headers.common["Authorization"] = localStorage.getItem(
				"token"
			);
			axios({
				url: "/api/hello-user",
				method: "GET",
			}).then((res) => {
				this.userid = res.data.userid;
				this.username = res.data.username;
				if (res.data.usertype === 'club') {
					this.isClub = 1;
				}
			});
			axios({
				url: "/api/file/get?type=" + this.filetype,
				method: "GET",
			}).then((res) => {
				// console.log(res);
				this.tableData = res.data.data;
				for (let item of this.tableData) {
					item.time = item.time.split(".")[0] + ' UTC+08';
				}
			});
		}
		if (this.filetype === '赛事章程') {
			this.tipString = '每一个赛事请各协会会长打包上传一个完整zip'
		}
		else if (this.filetype === '体育活动') {
			this.tipString = '每一个活动请整合上传一个pdf！'
		}
		else if (this.filetype === '工作计划') {
			this.tipString = '请上传pdf'
		}
	},
	methods: {
		messageBox(msg, type) {
			this.$message[type](msg);
		},
		submitUpload() {
			// console.log("CLicked!");
			this.$refs.upload.submit();
		},
		tableRowClassName({row, }) {
			if (row.status === '通过') {
				return 'success-row';
			}
			if (row.status === '不通过') {
				return 'warning-row';
			}
			return '';
		},
		sleep(ms) {
			return new Promise((resolve) => setTimeout(resolve, ms));
		},
		handleRemove() {
			// console.log(file, fileList);
		},
		handlePreview() {
			// console.log(file);
		},
		downloader(data) {
			// 文件导出
			if (!data) {
				return;
			}
			let url = window.URL.createObjectURL(new Blob([data]));
			let link = document.createElement("a");
			link.style.display = "none";
			link.href = url;
			link.setAttribute("download", "");

			document.body.appendChild(link);
			link.click();
		},
		convertRes2Blob(response) {
			// 提取文件名
			let fileName = "123";
			// String(response.headers["content-disposition"]).match(
			// 	/filename=(.*)/
			// )[1];
			// 将二进制流转为blob
			let blob = new Blob([response.data], {
				type: "application/octet-stream,charset=UTF-8",
			});
			if (typeof window.navigator.msSaveBlob !== "undefined") {
				// 兼容IE，window.navigator.msSaveBlob：以本地方式保存文件
				window.navigator.msSaveBlob(blob, decodeURI(fileName));
			} else {
				// 创建新的URL并指向File对象或者Blob对象的地址
				let blobURL = window.URL.createObjectURL(blob);
				// 创建a标签，用于跳转至下载链接
				let tempLink = document.createElement("a");
				tempLink.style.display = "none";
				tempLink.href = blobURL;
				tempLink.setAttribute("download", decodeURI(fileName));
				// 兼容：某些浏览器不支持HTML5的download属性
				if (typeof tempLink.download === "undefined") {
					tempLink.setAttribute("target", "_blank");
				}
				// 挂载a标签
				document.body.appendChild(tempLink);
				tempLink.click();
				document.body.removeChild(tempLink);
				// 释放blob URL地址
				window.URL.revokeObjectURL(blobURL);
			}
		},
		beforeUpload(file) {
			const isLt20M = file.size / 1024 / 1024 < 20;
			if (!isLt20M) {
				this.$message.error("上传单个文件大小不能超过 20MB!");
			}
			return isLt20M;
		},
		uploadSectionFile(fileObj) {
			let dataObj = new FormData();
			dataObj.append("file", fileObj.file);
			dataObj.append("user", String(this.userid));
			dataObj.append("type", this.filetype);
			// Params here need to be customised
			// console.log(fileObj.file);
			if (localStorage.getItem("token")) {
				axios.defaults.headers.common["Authorization"] = localStorage.getItem(
					"token"
				);
			}
			axios({
				method: "POST",
				url: "/api/file/upload",
				headers: {
					"Content-Type": "multipart/form-data",
				},
				data: dataObj,
			})
				.then(async (res) => {
					// console.log("successfully Uploaded");
					// console.log(res);
					this.messageBox(
						"上传成功，请等待管理员审阅 文件id为" + res.data.id,
						"success"
					);
					await this.sleep(250);
					this.$router.go(0);
				})
				.catch((err) => {
					console.log(err.response.status);
					if (err.status == 413) {
						this.messageBox("上传失败，文件过大", "error");
					} else {
						this.messageBox(
							"上传失败，"+err?.response?.data?.Message ?? "网络断开或神秘错误", "error");
					}
				});
		},
		deleteFile(id) {
			this.$confirm("确定要删除文件吗？", "提示", {
					confirmButtonText: "确定",
					cancelButtonText: "取消",
					type: "warning",
				})
				.then(() => {
					axios({
						url: "/api/file/delete",
						method: "POST",
						headers: {
							Authorization: localStorage.getItem("token"),
						},
						data: qs.stringify({
							id: id
						}),
					})
						.then(() => {
							this.messageBox(
								"删除成功",
								"success"
							);
							axios({
								url: "/api/file/get?type=" + this.filetype,
								method: "GET",
								headers: {
									Authorization: localStorage.getItem("token"),
								},
							}).then((res) => {
								// console.log(res);
								this.tableData = res.data.data;
								for (let item of this.tableData) {
									item.time = item.time.split(".")[0] + ' UTC+08';
								}
							});
						})
						.catch((err) => {
							this.messageBox(
								"删除失败，" + err?.response?.data?.Message ?? "网络断开或神秘错误",
								"error"
							);
							console.log(err.response);
						});
					})
				.catch(() => {
					this.$message({
						type: "info",
						message: "已取消操作",
					});
				});
		},
	},
};
</script>